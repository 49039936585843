import classnames from 'classnames'
import { useField } from 'formik'
import React from 'react'
import { Dropdown } from '../../popper'

export const Field = ({
  className,
  disabled,
  help,
  label,
  name,
  onChange,
  options,
  placeholder = '--- Select ---',
}) => {
  const [field, { error, touched }, { setValue }] = useField(name)
  const showError = touched && error
  const option = options.find((option) => option.value === field.value)

  return (
    <div className={classnames('form-group', className)}>
      {label && (
        <div className='tw-flex tw-items-center tw-mb-2'>
          <label
            children={label}
            className={classnames('tw-mb-0', { 'tw-text-red-700': showError })}
            style={{ display: 'block', flex: 1 }}
          />

          {help}
        </div>
      )}

      <Dropdown.Provider>
        <Dropdown.ToggleButton
          className={classnames('btn btn-input tw-w-full', {
            'is-invalid': showError,
          })}
          disabled={disabled}
        >
          {option ? option.label : field.value || placeholder}
        </Dropdown.ToggleButton>
        <Dropdown.Menu maxHeight={240} strategy='fixed' sameWidth>
          {options.map((option) => (
            <div
              children={option.label}
              className='dropdown-item'
              key={option.value}
              onClick={() => {
                setValue(option.value)
                onChange?.(option.value)
              }}
              onKeyUp={(_event) => e.key === 'Enter' && setValue(option.value)}
            />
          ))}
        </Dropdown.Menu>
      </Dropdown.Provider>
    </div>
  )
}
