import { handleError } from '@blissbook/ui/util/errors'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Formik } from 'formik'
import React from 'react'
import { Field, FormSubmit } from '../form'
import * as Modal from './modal'

type ConfirmProps = {
  action?: string
  actionSubmitting?: string
  body: React.ReactNode
  challenge?: string
  onSubmit: () => void
  title?: string
  width?: number
}

export const ConfirmModal = Modal.wrap<ConfirmProps>(
  ({
    action,
    actionSubmitting,
    body,
    challenge,
    onSubmit,
    title,
    width = 500,
    ...props
  }) => {
    const { onClose } = props

    return (
      <Modal.Component
        {...props}
        shouldCloseOnEsc
        shouldCloseOnOverlayClick
        width={width}
      >
        <Modal.Content>
          <Modal.Header hideCloseButton>
            <Modal.Title>{title}</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <ConfirmForm
              action={action}
              actionSubmitting={actionSubmitting}
              body={body}
              challenge={challenge}
              onCancel={onClose}
              onSubmit={async () => {
                if (onSubmit) await onSubmit()
                onClose()
              }}
            />
          </Modal.Body>
        </Modal.Content>
      </Modal.Component>
    )
  },
)

type ConfirmFormProps = ConfirmProps & {
  disabled?: boolean
  onCancel?: () => void
}

export const ConfirmForm = ({
  action = 'Submit',
  actionSubmitting,
  body,
  challenge = '',
  disabled,
  onCancel,
  onSubmit,
  title,
  ...props
}: ConfirmFormProps) => (
  <Formik
    initialValues={{
      challenge: '',
    }}
    onSubmit={async (_values, { setStatus }) => {
      setStatus({ isSubmitting: true })

      try {
        await onSubmit()
      } catch (error) {
        handleError(error)
        setStatus()
      }
    }}
  >
    {({ handleSubmit, status: { isSubmitting } = {}, values }) => (
      <form {...props} onSubmit={handleSubmit}>
        <fieldset disabled={disabled || isSubmitting}>
          <div className='tw-mb-2'>
            {body}{' '}
            {!!challenge && (
              <>
                Enter <strong>{challenge}</strong> to confirm.
              </>
            )}
          </div>

          {!!challenge && <Field type='text' name='challenge' autoFocus />}

          <FormSubmit
            disabled={values.challenge !== challenge}
            isSubmitting={isSubmitting}
            onCancel={onCancel}
          >
            {isSubmitting ? actionSubmitting || action : action}
            {isSubmitting && (
              <FontAwesomeIcon className='tw-ml-2' icon='spinner' spin />
            )}
          </FormSubmit>
        </fieldset>
      </form>
    )}
  </Formik>
)
