import type { PeopleExpressionTooltipContentProps } from '@blissbook/ui/application/expression'
import type { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import isFunction from 'lodash/isFunction'
import React, { useCallback } from 'react'

export const bookmarkIcon: IconProp = ['fad', 'file']

export type Bookmark = {
  contextIcon?: JSX.Element
  href: string
  label: string
}

export type BookmarksFunction = () => Bookmark[]

export type BookmarksInput = Bookmark[] | BookmarksFunction

function getBookmarks(bookmarks: BookmarksInput = []) {
  if (isFunction(bookmarks)) return bookmarks()
  return bookmarks
}

export function findBookmark(bookmarks: BookmarksInput, href?: string) {
  bookmarks = getBookmarks(bookmarks)
  return bookmarks.find((b) => b.href === href)
}

function mapBookmarkToOption({ contextIcon, label, ...bookmark }: Bookmark) {
  return {
    ...bookmark,
    children: (
      <div className='tw-flex tw-items-center tw-justify-between tw-gap-2'>
        <div className='tw-flex-1 tw-min-w-0 tw-flex tw-items-center'>
          <FontAwesomeIcon className='dropdown-item-icon' icon={bookmarkIcon} />
          <div className='tw-flex-1 tw-min-w-0 ellipsis' title={label}>
            {label}
          </div>
        </div>

        {contextIcon}
      </div>
    ),
    key: bookmark.href,
  }
}

export function useBookmarkOptions(bookmarks?: BookmarksInput) {
  return useCallback(
    (search: string) => {
      if (!bookmarks) return
      bookmarks = getBookmarks(bookmarks)
      search = search.toLowerCase()
      return bookmarks
        .filter((b) => b.label.toLowerCase().indexOf(search) !== -1)
        .map(mapBookmarkToOption)
    },
    [bookmarks],
  )
}
