import type {
  PropertyValueInput,
  UrlPropertyValueInput,
} from '@blissbook/lib/properties'
import { emptyUrl, formatHref } from '@blissbook/lib/sanitize'
import { Input } from '@blissbook/ui/lib'
import React, { useState } from 'react'
import type { PropertyValueEditorProps } from './PropertyValueEditor'

export function UrlPropertyValueEditor({
  initialValue,
  onChange,
}: PropertyValueEditorProps & {
  initialValue: UrlPropertyValueInput | null
  onChange: (value: PropertyValueInput | null) => void
}) {
  const [value, setValue] = useState(initialValue)

  function handleChange(url: string) {
    const formattedUrl = formatHref(url)
    const value = formattedUrl !== emptyUrl ? { url: formattedUrl } : null
    if (value) {
      onChange(value)
      setValue(value)
    }
  }

  return (
    <Input
      autoFocus
      className='tw-w-full'
      debounce={500}
      onChangeValue={handleChange}
      placeholder='Enter URL...'
      type='url'
      value={value ? value.url : ''}
    />
  )
}
