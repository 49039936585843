import React, { type ReactNode } from 'react'
import { UnknownErrorPage } from './UnknownErrorPage'

export function ErrorBoundaryFallback({
  error,
  children,
}: {
  error?: any
  children: ReactNode
}) {
  return error ? <UnknownErrorPage /> : <>{children}</>
}
