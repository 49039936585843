import type { Property } from '@blissbook/lib/properties'
import { type DragItem, useSortable } from '@blissbook/ui/hooks/useSortable'
import { SearchInput, Tooltip } from '@blissbook/ui/lib'
import { cx } from '@emotion/css'
import type { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useMemo, useState } from 'react'
import { Button } from '../buttons'
import { Dropdown } from '../popper'
import { stringifyColumnKey } from './ColumnKey'
import type { ColumnState } from './ColumnState'
import { pickColumnState } from './ColumnState'

export type PropertyColumn = ColumnState & {
  icon?: IconProp
  label: string
  property?: Property
  required?: boolean
}

type ColumnOptions = {
  search: string
}

function filterColumns(columns: PropertyColumn[], { search }: ColumnOptions) {
  if (!search) return columns

  search = search.toLowerCase()
  function isMatch(column: PropertyColumn) {
    if (!search) return true
    return column.label.toLowerCase().includes(search)
  }

  return columns.filter(isMatch)
}

function useFilteredColumns(columns: PropertyColumn[], options: ColumnOptions) {
  const { search } = options
  return useMemo(() => filterColumns(columns, options), [columns, search])
}

function PropertyListItem({
  canSort,
  column,
  index,
  onDrop,
  onMove,
  onSelect,
  toggleHidden,
}: {
  canSort?: boolean
  column: PropertyColumn
  index: number
  onDrop: (item: DragItem) => void
  onMove: (dragIndex: number, hoverIndex: number) => void
  onSelect: () => void
  toggleHidden: (column: PropertyColumn) => void
}) {
  const { hidden, property } = column

  const sortable = useSortable({
    disabled: !canSort,
    id: `${column.field}-${column.path}`,
    index,
    onDrop,
    onMove,
    type: 'column',
  })

  return (
    <Dropdown.Item
      className='tw-flex tw-items-center tw-justify-between tw-gap-2 tw-px-2'
      key={`${column.field}-${column.path}`}
      onClick={(event) => {
        event.stopPropagation()

        const { property } = column
        if (property) onSelect()
      }}
      ref={sortable.ref}
      style={{
        opacity: sortable.isDragging ? 0 : 1,
      }}
    >
      <div>
        {canSort && (
          <FontAwesomeIcon
            className='dropdown-item-icon'
            icon={['far', 'grip-dots-vertical']}
          />
        )}
        {column.icon && (
          <FontAwesomeIcon className='dropdown-item-icon' icon={column.icon} />
        )}
        {column.label}
      </div>

      <div className='tw-flex tw-items-center tw-gap-2'>
        <Tooltip
          content={hidden ? 'Show Column' : 'Hide Column'}
          disabled={column.required}
        >
          <Button
            className='btn-icon tw-p-1'
            disabled={column.required}
            onClick={(event) => {
              event.stopPropagation()
              toggleHidden(column)
            }}
          >
            <FontAwesomeIcon
              icon={hidden ? ['far', 'eye-slash'] : ['far', 'eye']}
            />
          </Button>
        </Tooltip>

        <FontAwesomeIcon
          className={cx('tw-text-gray-500', !property && 'tw-invisible')}
          icon={'angle-right'}
        />
      </div>
    </Dropdown.Item>
  )
}

export function PropertiesList({
  columns,
  onChangeColumns,
  onSelectPropertyId,
}: {
  columns: PropertyColumn[]
  onChangeColumns: (columnKeys: ColumnState[]) => void
  onSelectPropertyId: (propertyId: string) => void
}) {
  // Filtering columns
  const [search, setSearch] = useState('')
  const filteredColumns = useFilteredColumns(columns, {
    search,
  })
  const requiredColumns = columns.filter((column) => column.required)

  // Sorting columns
  const canSort = !search
  const [sortableColumns = filteredColumns, setSortableColumns] =
    useState<PropertyColumn[]>()
  const shownColumns = sortableColumns.filter((column) => !column.hidden)
  const hiddenColumns = sortableColumns.filter((column) => column.hidden)
  const sortedColumns = [...shownColumns, ...hiddenColumns]

  // Move the
  function onMoveColumn(dragIndex: number, hoverIndex: number) {
    const { hidden } = sortedColumns[hoverIndex]
    const newColumns = [...sortedColumns]
    newColumns.splice(dragIndex, 1)
    newColumns.splice(hoverIndex, 0, { ...sortedColumns[dragIndex], hidden })
    setSortableColumns(newColumns)
  }

  // When a columns is dropped, we need to update the column keys
  function onDropColumn() {
    onChangeColumns(sortableColumns.map(pickColumnState))
    setSortableColumns(undefined)
  }

  function hideAllColumns() {
    onChangeColumns(
      columns.map((column) => ({
        ...pickColumnState(column),
        hidden: !column.required,
      })),
    )
  }

  function showAllColumns() {
    onChangeColumns(
      columns.map((column) => ({
        ...pickColumnState(column),
        hidden: false,
      })),
    )
  }

  function toggleHidden(toggleColumn: PropertyColumn) {
    const newColumns = []

    // Retain shown columns
    const shownColumns = columns.filter((column) => !column.hidden)
    for (const column of shownColumns) {
      if (column === toggleColumn) continue
      newColumns.push(pickColumnState(column))
    }

    // Move to bottom of show or top of hidden
    newColumns.push({
      ...pickColumnState(toggleColumn),
      hidden: !toggleColumn.hidden,
    })

    // Retain hidden columns
    const hiddenColumns = columns.filter((column) => column.hidden)
    for (const column of hiddenColumns) {
      if (column === toggleColumn) continue
      newColumns.push(pickColumnState(column))
    }

    onChangeColumns(newColumns)
  }

  return (
    <div>
      <SearchInput
        autoFocus
        onChangeValue={setSearch}
        placeholder='Search for a column...'
        value={search}
      />

      <div
        className='tw-relative tw-overflow-y-auto'
        style={{ maxHeight: 385 }}
      >
        {shownColumns.length > 0 && (
          <>
            <Dropdown.Header className='tw-sticky tw-top-0 tw-flex tw-items-center tw-justify-between tw-py-2 tw-bg-white tw-z-10'>
              Shown in table
              {!search && shownColumns.length > requiredColumns.length && (
                <Button className='btn-link' onClick={hideAllColumns}>
                  Hide all
                </Button>
              )}
            </Dropdown.Header>

            {shownColumns.map((column) => (
              <PropertyListItem
                canSort={canSort}
                column={column}
                index={sortedColumns.indexOf(column)}
                key={stringifyColumnKey(column)}
                onDrop={onDropColumn}
                onMove={onMoveColumn}
                onSelect={() => onSelectPropertyId(column.property.id)}
                toggleHidden={toggleHidden}
              />
            ))}
          </>
        )}

        {hiddenColumns.length > 0 && (
          <>
            <Dropdown.Header className='tw-sticky tw-top-0 tw-flex tw-items-center tw-justify-between tw-py-2 tw-bg-white tw-z-10'>
              Hidden in table
              {!search && (
                <Button className='btn-link' onClick={showAllColumns}>
                  Show all
                </Button>
              )}
            </Dropdown.Header>

            {hiddenColumns.map((column) => (
              <PropertyListItem
                canSort={canSort}
                column={column}
                index={sortedColumns.indexOf(column)}
                key={stringifyColumnKey(column)}
                onDrop={onDropColumn}
                onMove={onMoveColumn}
                onSelect={() => onSelectPropertyId(column.property.id)}
                toggleHidden={toggleHidden}
              />
            ))}
          </>
        )}
      </div>
    </div>
  )
}
