import {
  handbookCollaboratorRoles,
  handbookEditorRole,
  handbookOwnerRole,
  handbookPublisherRole,
} from '../handbook'
import type { Role } from '../types'
import { mapById } from '../util'
import { teamPermissions } from './permissions'

export type TeamRoleId = 'editor' | 'publisher' | 'owner'

export type TeamRole = Role & {
  id: TeamRoleId | null
  values?: {
    canReport?: boolean
    canSuperBrand?: boolean
  }
}

export const teamNoneRole: TeamRole = {
  id: null,
  label: 'Limited Access',
  description:
    'Can view specific audience members’ current or historical live documents from the Documentation Center.',
  permissionIds: [],
}

export const teamSuperBrandingRole: TeamRole = {
  ...teamNoneRole,
  label: 'Super Brander',
  description: 'Can edit custom CSS on all team handbooks.',
  permissionIds: ['handbook.draft.brand', 'handbook.draft.editCss'],
  values: {
    canSuperBrand: true,
  },
}

export const teamReportingRole: TeamRole = {
  ...teamNoneRole,
  label: 'Reporting-only',
  description:
    'Can report on all documents and can view specific audience members’ current or historical live documents from the Documentation Center.',
  permissionIds: [
    'documents.view',
    'handbook.query',
    'handbook.history.view',
    'handbook.report',
    'people.report',
  ],
  values: {
    canReport: true,
  },
}

export const teamEditorRole: TeamRole = {
  id: 'editor',
  label: 'Editor',
  description: 'Can view & edit any document.',
  color: 'purple',
  permissionIds: [
    ...handbookEditorRole.permissionIds,
    'team.teammates.view',
    'assets.manage',
    'documents.edit',
    'documents.view',
    'lawChanges.view',
    'policyLibrary.view',
    'properties.view',
  ],
}

export const teamPublisherRole: TeamRole = {
  id: 'publisher',
  label: 'Publisher',
  description: 'Can view, edit & publish any document.',
  color: 'purple',
  permissionIds: [
    ...handbookPublisherRole.permissionIds,
    'team.teammates.view',
    'documents.edit',
    'documents.publish',
    'documents.view',
    'lawChanges.view',
    'policyLibrary.view',
    'properties.view',
  ],
}

export const teamOwnerRole: TeamRole = {
  id: 'owner',
  label: 'Team Owner',
  description:
    'Can create new documents. They can view, edit & publish any document. They can also manage team settings & collaborators permissions.',
  color: 'red',
  permissionIds: [
    ...teamPermissions.map((p) => p.id),
    ...handbookOwnerRole.permissionIds,
    'handbook.report',
  ],
  values: {
    canReport: true,
  },
}

export const teamRoles = [
  teamNoneRole,
  teamEditorRole,
  teamPublisherRole,
  teamOwnerRole,
]

export const teamRolesReverse = [...teamRoles].reverse()

export const teamRolesById = mapById(teamRoles)

export const addTeamRoles = [...teamRoles]
addTeamRoles[0] = teamReportingRole

export const addTeamRolesReverse = [...addTeamRoles].reverse()

export function getTeamRole(roleId: TeamRoleId | null) {
  return teamRolesById.get(roleId) || teamNoneRole
}

export function getTeamRoleIndex(roleId: TeamRoleId | null) {
  const teamRole = getTeamRole(roleId)
  return teamRoles.indexOf(teamRole)
}

/** Determinme the maximum role that these roleIds map to */
export function getMaxTeamRoleIndex(roleIdSet: Set<TeamRoleId>) {
  let maxIndex = 0
  for (const roleId of roleIdSet) {
    const index = getTeamRoleIndex(roleId)
    maxIndex = Math.max(maxIndex, index)
  }
  return maxIndex
}

/** Determine the roles that this teammate can be added to on a handbook */
export function getAllowedHandbookCollaboratorRoles(
  teamRoleId: TeamRoleId | undefined,
) {
  const teamRoleIndex = getTeamRoleIndex(teamRoleId)
  const minHandbookRoleIndex = Math.max(teamRoleIndex - 1, 0) // Subtract 1, because handbooks don't have the "none / reporting" role
  return handbookCollaboratorRoles.slice(minHandbookRoleIndex)
}
