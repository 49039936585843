import React from 'react'
import { FullScreenPage } from '../layout'

export function ErrorPage({
  children,
  heading,
}: {
  children?: React.ReactNode
  heading: string
}) {
  return (
    <FullScreenPage>
      <div className='tw-flex tw-flex-col tw-gap-4 container'>
        <div className='tw-text-6xl tw-font-semibold tw-leading-snug'>
          {heading}
        </div>
        <div className='tw-text-3xl tw-font-semibold tw-leading-snug'>
          {children}
        </div>
      </div>
    </FullScreenPage>
  )
}
