import {
  type ColorPaletteInput,
  resolveColorPalette,
} from '@blissbook/lib/blissbook'
import { cx } from '@emotion/css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import {
  type CSSProperties,
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import tinycolor from 'tinycolor2'
import { Button } from '../buttons'
import { Dropdown, Popper } from '../popper'
import { Tooltip } from '../tooltip'
import { SliderInput } from './SliderInput'

type ColorInput = string | tinycolor.Instance

type ColorState = {
  hex: string
  alpha: number
}

function getColorState(input: ColorInput): ColorState {
  const color = tinycolor(input)
  const hex = color.toHex()
  const alpha = color.getAlpha() * 100
  return { hex, alpha }
}

function getColorValue(input: ColorInput) {
  if (!input) return

  const color = tinycolor(input)
  const alpha = color.getAlpha()
  return alpha < 1 ? color.toHex8String() : color.toHexString()
}

function getColorFromState(state: ColorState) {
  const { alpha, hex } = state
  if (hex.length !== 6) return

  const color = tinycolor(hex)
  color.setAlpha(alpha / 100)
  if (color.isValid()) return color
}

function useColorState(inputValue: string) {
  const [state, setState] = useState(() => getColorState(inputValue))

  useEffect(() => {
    setState(getColorState(inputValue))
  }, [inputValue])

  const color = useMemo(() => getColorFromState(state), [state])

  const setColor = useCallback(
    (input: ColorInput) => setState(getColorState(input)),
    [],
  )

  const setAlpha = useCallback(
    (alpha: number) => setState({ ...state, alpha: alpha }),
    [state],
  )

  const setHex = useCallback(
    (hex: string) => setState({ ...state, hex }),
    [state],
  )

  return {
    alpha: Math.round(state.alpha),
    color,
    hex: state.hex,
    setAlpha,
    setColor,
    setHex,
  }
}

const systemColors = [
  'rgb(255,255,255)',
  'rgb(237,249,255)',
  'rgb(246,242,253)',
  'rgb(253,240,241)',
  'rgb(255,248,237)',
  'rgb(255,253,238)',
  'rgb(245,251,243)',
  'rgb(237,252,249)',
  'rgb(204,204,204)',
  'rgb(190,233,255)',
  'rgb(223,208,250)',
  'rgb(252,204,206)',
  'rgb(255,230,195)',
  'rgb(255,250,195)',
  'rgb(221,244,211)',
  'rgb(189,245,236)',
  'rgb(153,153,153)',
  'rgb(111,207,255)',
  'rgb(184,153,246)',
  'rgb(245,141,150)',
  'rgb(255,201,122)',
  'rgb(255,243,123)',
  'rgb(183,230,159)',
  'rgb(109,234,214)',
  'rgb(102,102,102)',
  'rgb(0,168,255)',
  'rgb(128,67,239)',
  'rgb(238,49,62)',
  'rgb(255,157,0)',
  'rgb(255,235,0)',
  'rgb(124,211,78)',
  'rgb(0,218,181)',
  'rgb(51,51,51)',
  'rgb(0,113,173)',
  'rgb(85,45,158)',
  'rgb(159,32,41)',
  'rgb(172,104,0)',
  'rgb(171,156,0)',
  'rgb(83,141,52)',
  'rgb(0,146,121)',
  'rgb(0,0,0)',
  'rgb(0,57,88)',
  'rgb(43,23,80)',
  'rgb(80,16,21)',
  'rgb(87,52,1)',
  'rgb(86,79,0)',
  'rgb(41,71,27)',
  'rgb(0,73,61)',
].map(getColorValue)

type LabelProps = React.LabelHTMLAttributes<HTMLLabelElement>

const Label: React.FC<LabelProps> = ({ className, ...props }) => (
  <label
    {...props}
    className={cx(
      'tw-block tw-font-normal tw-text-gray-500 tw-select-none tw-uppercase',
      className,
    )}
    css={{ fontSize: 10 }}
  />
)

const useShowSystemColorsState = () => {
  const [showSystemColors, setShowSystemColors] = useState(
    localStorage.showSystemColors === 'true',
  )

  const onChangeShowSystemColors = (showSystemColors: boolean) => {
    localStorage.showSystemColors = showSystemColors
    setShowSystemColors(showSystemColors)
  }

  return [showSystemColors, onChangeShowSystemColors] as const
}

type ColorInputProps = {
  disabled?: boolean
  onChanging?: (value: string) => void
  onChange: (value: string) => void
  onRemove?: () => void
  palette?: ColorPaletteInput
  showAlpha?: boolean
  value: string
}

export const ColorInputMenu: React.FC<ColorInputProps> = ({
  onChanging,
  onChange,
  onRemove,
  palette,
  showAlpha,
  value,
  ...props
}) => {
  const { alpha, color, hex, setAlpha, setColor, setHex } = useColorState(value)
  const activeValue = color ? color.toHexString() : undefined
  const [resolvedColorPalette] = useState(() => resolveColorPalette(palette))
  const [showSystemColors, setShowSystemColors] = useShowSystemColorsState()

  const submitColor = useCallback(
    (color: tinycolor.Instance) => {
      const value = getColorValue(color)
      onChange(value)
    },
    [onChange],
  )

  function onSelect(value: string) {
    const color = tinycolor(value)
    color.setAlpha(alpha / 100)
    setColor(color)
    submitColor(color)
  }

  return (
    <Popper.Menu
      {...props}
      className='tw-p-6'
      css={{ width: 252 }}
      placement='bottom-start'
      showArrow
    >
      <div className='tw-flex tw-gap-3 tw-items-center'>
        <ColorSwatch hideTooltip size={30} value={getColorValue(color)} />

        <div className='tw-relative tw-flex-1'>
          <div className='tw-absolute tw-bottom-0 tw-text-gray-600 tw-italic tw-p-1.5 tw-pointer-events-none tw-text-sm tw-top-0'>
            #
          </div>

          <input
            className={cx(
              'form-control tw-pl-4 tw-uppercase',
              color ? undefined : 'has-error',
            )}
            maxLength={6}
            onChange={(event) => {
              const hex = event.target.value
              const color = getColorFromState({ alpha, hex })
              setHex(hex)
              if (color) submitColor(color)
            }}
            placeholder='hex'
            required
            type='text'
            value={hex || ''}
          />
        </div>

        {onRemove && (
          <Tooltip content='Reset' placement='bottom'>
            <Button className='btn-icon tw-text-lg' onClick={() => onRemove()}>
              <FontAwesomeIcon icon={['far', 'droplet-slash']} />
            </Button>
          </Tooltip>
        )}
      </div>

      {showAlpha && (
        <div className='tw-flex tw-gap-3 tw-items-center tw-mt-2'>
          <div className='tw-text-center tw-text-sm' css={{ width: 32 }}>
            {alpha}%
          </div>

          <SliderInput
            className='tw-flex-1'
            onAfterChange={(alpha) => {
              const color = getColorFromState({ alpha, hex })
              setAlpha(alpha)
              if (color) submitColor(color)
            }}
            onChange={(alpha) => {
              const color = getColorFromState({ alpha, hex })
              setAlpha(alpha)
              if (color && onChanging) {
                const value = getColorValue(color)
                onChanging(value)
              }
            }}
            value={alpha}
          />
        </div>
      )}

      {resolvedColorPalette && (
        <>
          <Label className='tw-mt-4 tw-mb-0'>Your Colors</Label>

          <ColorSwatchGrid
            activeValue={activeValue}
            className='tw-mt-2'
            onSelect={onSelect}
            size={20}
            values={resolvedColorPalette}
          />
        </>
      )}

      <Label
        className='tw-cursor-pointer tw-flex tw-items-center tw-mt-4 tw-mb-0'
        onClick={() => {
          setShowSystemColors(!showSystemColors)
        }}
      >
        More Colors
        <FontAwesomeIcon
          className='tw-ml-1'
          icon={showSystemColors ? 'caret-down' : 'caret-right'}
        />
      </Label>

      {showSystemColors && (
        <ColorSwatchGrid
          activeValue={activeValue}
          className='tw-mt-2'
          onSelect={onSelect}
          size={20}
          values={systemColors}
        />
      )}
    </Popper.Menu>
  )
}

type ColorInputButtonProps = ColorInputProps & {
  className?: string
  showCaret?: boolean
  size?: number
  style?: CSSProperties
}

export const ColorInputButton: React.FC<ColorInputButtonProps> = ({
  className,
  disabled,
  showCaret,
  size = 18,
  style,
  ...props
}) => {
  const { value } = props
  const [isOpen, setOpen] = useState(false)
  const [refNode, setRefNode] = useState<HTMLElement | null>()

  return (
    <Dropdown.Provider isOpen={isOpen} refNode={refNode} setOpen={setOpen}>
      <Dropdown.ToggleButton
        caret={showCaret ? undefined : false}
        className={cx('tw-flex', className)}
        disabled={disabled}
        style={style}
      >
        <ColorSwatch hideTooltip ref={setRefNode} size={size} value={value} />
      </Dropdown.ToggleButton>

      {isOpen && <ColorInputMenu {...props} />}
    </Dropdown.Provider>
  )
}

type ColorSwatchGridProps = {
  activeValue?: string
  className?: string
  onSelect?: (value: string) => void
  size?: number
  style?: CSSProperties
  values: string[]
}

export const ColorSwatchGrid = forwardRef<HTMLDivElement, ColorSwatchGridProps>(
  (
    { activeValue, className, onSelect, size, style, values, ...props },
    ref,
  ) => (
    <div
      {...props}
      className={cx('tw-grid tw-grid-cols-8 tw-gap-1.5', className)}
      ref={ref}
    >
      {values.map((value, index) => (
        <ColorSwatch
          active={tinycolor.equals(activeValue, value)}
          className='tw-cursor-pointer'
          key={index}
          onClick={() => {
            if (onSelect) onSelect(value)
          }}
          size={size}
          value={value}
        />
      ))}
    </div>
  ),
)

type ColorSwatchProps = React.HTMLAttributes<HTMLDivElement> & {
  active?: boolean
  className?: string
  hideTooltip?: boolean
  size?: number
  style?: CSSProperties
  value: string
}

export const ColorSwatch = forwardRef<HTMLDivElement, ColorSwatchProps>(
  (
    { active, className, hideTooltip, size = 16, style, value, ...props },
    ref,
  ) => (
    <Tooltip
      content={value}
      disabled={!value || hideTooltip}
      placement='bottom'
    >
      <div
        {...props}
        className={cx(
          'tw-flex tw-items-center tw-justify-center tw-rounded-full tw-select-none bg-checkerboard',
          className,
        )}
        css={{
          fontSize: 10,
        }}
        ref={ref}
        style={{
          ...style,
          boxShadow: active ? '0 0 3px 1px #5fb9dd' : undefined,
          height: size,
          width: size,
        }}
      >
        <div
          className='tw-h-full tw-rounded-full tw-w-full tw-border tw-border-black/20'
          style={{
            backgroundColor: value,
          }}
        />
      </div>
    </Tooltip>
  ),
)
