import { Tooltip } from '@blissbook/ui/lib'
import { cx } from '@emotion/css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import type { DocumentStatus } from './DocumentStatus'

export type DocumentStatusViewProps = {
  className?: string
  compact?: boolean
  value: DocumentStatus
}

export function DocumentStatusView({
  className,
  compact,
  value,
}: DocumentStatusViewProps) {
  return (
    <Tooltip content={value.label} disabled={!compact} placement='right'>
      <div className='tw-flex tw-items-center tw-gap-1'>
        <FontAwesomeIcon
          className={cx(value.className, className)}
          fixedWidth
          icon={value.icon}
        />

        {!compact && <span>{value.label}</span>}
      </div>
    </Tooltip>
  )
}
