import type { Node } from '@blissbook/lib/document/types'
import type { AudienceRootExpression } from '@blissbook/lib/expression'
import type { Annotation } from './annotation'

export type BlissbookSectionBackground = {
  animation?: string
  color?: string
  image?: string
  overlayColor?: string
  overlayOpacity?: number
}

export type BlissbookSectionVideo = {
  height: number
  url: string
  width: number
}

export type BlissbookSectionAttributes = {
  animation?: boolean
  backgroundColor?: string
  backgroundImage?: string
  backgroundOverlayColor?: string
  backgroundOverlayOpacity?: number
  bottom?: string
  bottomArrow?: boolean
  entryAngle?: string
  entryAnimation?: boolean
  goalAlign?: string
  headingBackgroundImage?: string
  height?: string
  image?: string
  maxColumns?: number
  photo?: string
  showChapterIcon?: boolean
  signatureType?: string
  signatureImage?: string
  statementAlign?: string
  subtitleAlign?: string
  textShadow?: boolean
  titleAlign?: string
  titleHighlight?: string
  titleHighlightColor?: string
  video?: BlissbookSectionVideo
}

export type BlissbookSectionAttributesKey = keyof BlissbookSectionAttributes

export type BlissbookSectionAttributesColorKey =
  | 'backgroundColor'
  | 'backgroundOverlayColor'
  | 'titleHighlightColor'

export const sectionAttrColorKeys: BlissbookSectionAttributesColorKey[] = [
  'backgroundColor',
  'backgroundOverlayColor',
  'titleHighlightColor',
]

export type BlissbookSectionContent = {
  body?: Node[]
  description?: Node[]
  goal?: Node[]
  headline?: Node[]
  introduction?: Node[]
  signature?: Node[]
  statement?: Node[]
  subtitle?: Node[]
  title?: Node[]
}

export type BlissbookSectionContentKey = keyof BlissbookSectionContent

export type BlissbookSectionEntry = {
  uid: string
  image?: string
  title?: Node[]
  titleAlign?: string
  subtitle?: Node[]
  subtitleAlign?: string
  body?: Node[]
}

export type BlissbookSectionEntryKey = keyof BlissbookSectionEntry

export type BlissbookSectionEntryContentKey = 'title' | 'subtitle' | 'body'

export type BlissbookSectionSettings = {
  audienceExpression?: AudienceRootExpression

  hidden: boolean
  hideToc: boolean
  languageCode?: string
  type: string
  theme?: string
  title?: string

  editAnnotation?: string
  viewAnnotation?: string
  viewAnnotationIds: string[]
}

export type BlissbookSection = BlissbookSectionSettings & {
  handbookId: number
  id: number
  version: number
  wysiwyg?: string

  attrs?: BlissbookSectionAttributes
  fragments: BlissbookSectionContent
  listEntries?: BlissbookSectionEntry[]
  annotations?: Annotation[]
}

export type BlissbookSectionState = {
  dirtyFields?: string[]
}

export type BlissbookSectionVersion = BlissbookSection & {
  createdAt: Date
}

export type SectionImageAttrKey =
  | 'backgroundImage'
  | 'headingBackgroundImage'
  | 'image'
  | 'photo'
  | 'signatureImage'

export const sectionImageAttrKeys: SectionImageAttrKey[] = [
  'backgroundImage',
  'headingBackgroundImage',
  'image',
  'photo',
  'signatureImage',
]

// These should be ordered by importance in search
export const sectionContentTitleKeys: BlissbookSectionContentKey[] = [
  'title',
  'subtitle',
  'goal',
  'statement',
]

// These should be ordered by importance in search
export const sectionContentBodyKeys: BlissbookSectionContentKey[] = [
  'headline',
  'introduction',
  'body',
  'description',
  'signature',
]

// These should be ordered by importance in search
export const sectionContentKeys = [
  ...sectionContentTitleKeys,
  ...sectionContentBodyKeys,
]

export type SectionAnnotationKey = 'editAnnotation' | 'viewAnnotation'

export type SectionEntryImageAttrKey = 'image'

export const sectionEntryImageAttrKeys: SectionEntryImageAttrKey[] = ['image']

// These should be ordered by importance in search
export const sectionEntryTitleKeys: BlissbookSectionEntryContentKey[] = [
  'title',
  'subtitle',
]

// These should be ordered by importance in search
export const sectionEntryBodyKeys: BlissbookSectionEntryContentKey[] = ['body']

// These should be ordered by importance in search
export const sectionEntryContentKeys = [
  ...sectionEntryTitleKeys,
  ...sectionEntryBodyKeys,
]

export type BlissbookHandbookContent = {
  body?: Node[]
  title?: Node[]
}

export type BlissbookHandbookAcknowledgementFormAttributes = {
  forceSign?: boolean
}

export type BlissbookHandbookAcknowledgementForm =
  BlissbookHandbookAcknowledgementFormAttributes & BlissbookHandbookContent

export type BlissbookHandbookContentKey = keyof BlissbookHandbookContent

export const handbookContentKeys: BlissbookHandbookContentKey[] = [
  'body',
  'title',
]

export type BlissbookHandbookSettings = {
  customDomain?: string
  defaultLanguageCode: string
  isPrintDisabled: boolean
  name: string
  public: boolean
  publicToken: string
}

export type BlissbookHandbook = BlissbookHandbookSettings & {
  id: number
  slug: string
  acknowledgementForm: BlissbookHandbookAcknowledgementForm
  branding: BlissbookHandbookBranding
  contactFragments: BlissbookHandbookContent
  content: Node[]
  wysiwyg?: string
}

export type BlissbookHandbookBranding = {
  blackColor: string
  bodyFont: object
  customHtml?: string
  favicon?: string
  handbookCss?: string
  headingFont: object
  lessButtonText: string
  logo?: string
  menuButtonColor?: string
  moreButtonText: string
  primaryColor: string
  printCss?: string
  scriptFont: object
  signButtonColor?: string
  whiteColor: string
}

export type BlissbookHandbookBrandingColorKey =
  | 'blackColor'
  | 'primaryColor'
  | 'whiteColor'

export type BlissbookHandbookBrandingFontKey =
  | 'bodyFont'
  | 'headingFont'
  | 'scriptFont'

// Resolved

export type ResolvedBlissbookHandbook = BlissbookHandbook & {
  languageCode: string
  languageCodes: string[]
  timestamp: Date
  version: number
}
