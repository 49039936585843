import { useMouseDownOutside } from '@blissbook/ui/hooks/useMouseDownOutside'
import { Dropdown } from '@blissbook/ui/lib'
import { cx } from '@emotion/css'
import type { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'

export const propertyRowClassName =
  'tw-group tw-flex tw-items-start tw-gap-1 tw-text-sm'

export const propertyLabelClassName =
  'tw-flex tw-items-center tw-justify-start tw-gap-1 tw-whitespace-nowrap tw-w-[140px] -tw-ml-1 tw-px-1 tw-py-1 tw-text-gray-600 tw-rounded ellipsis'

export const propertyValueClassName =
  'tw-flex-1 tw-min-w-0 tw-px-1 tw-py-1 tw-rounded-md'

export function PropertyRowLayout({
  disabled,
  icon,
  label,
  propertyEditor,
  valueEditor,
  valueViewer,
}: {
  disabled?: boolean
  icon: IconProp
  label: string
  propertyEditor?: React.ReactNode
  valueEditor?: React.ReactNode
  valueViewer: React.ReactNode
}) {
  const [valueNode, setValueNode] = useState<HTMLElement>()
  const [showValueEditor, setShowValueEditor] = useState(false)

  useMouseDownOutside(() => {
    setShowValueEditor(false)
  }, [valueNode])

  function handleClickValue() {
    if (!disabled && valueEditor) setShowValueEditor(true)
  }

  return (
    <div className={propertyRowClassName}>
      <Dropdown.Provider>
        <Dropdown.ToggleButton
          caret={false}
          className={cx(
            propertyLabelClassName,
            propertyEditor && 'hover:tw-bg-gray-100',
          )}
          disabled={!propertyEditor}
        >
          <FontAwesomeIcon fixedWidth icon={icon} />
          <span className='ellipsis'>{label}</span>
        </Dropdown.ToggleButton>
        <Dropdown.Menu className='tw-p-3' style={{ width: 300 }}>
          {propertyEditor}
        </Dropdown.Menu>
      </Dropdown.Provider>

      {showValueEditor ? (
        <div
          className={`${propertyValueClassName} tw-border-2 tw-border-gray-200`}
          ref={setValueNode}
        >
          {valueEditor}
        </div>
      ) : (
        <div
          className={cx(
            propertyValueClassName,
            !disabled &&
              !showValueEditor &&
              'hover:tw-bg-gray-100 hover:tw-cursor-pointer',
          )}
          onClick={handleClickValue}
          onKeyUp={(e) => {
            if (e.key === 'Enter') handleClickValue()
          }}
          ref={setValueNode}
        >
          {valueViewer}
        </div>
      )}
    </div>
  )
}
