import classnames from 'classnames'
import { useField } from 'formik'
import React from 'react'
import { Textarea } from '../../input/Textarea'

export const Field = ({
  append,
  className,
  id,
  label,
  onChange,
  style,
  ...props
}) => {
  const { name } = props
  const [field, { error, touched }] = useField(name)
  const showError = touched && error
  id = id || name
  return (
    <div className={classnames('form-group', className)} style={style}>
      {label && (
        <label
          className={classnames({ 'tw-text-red-700': showError })}
          htmlFor={id}
          style={{ display: 'block' }}
        >
          {label}
        </label>
      )}

      <div className='tw-flex tw-items-center'>
        <Textarea
          {...field}
          {...props}
          id={id}
          className={classnames('form-control', { 'is-invalid': showError })}
          onChange={(event, value) => {
            field.onChange(event)
            if (onChange) onChange(event, value)
          }}
        />
        {append && <div className='tw-ml-1'>{append}</div>}
      </div>

      {showError && field.value && (
        <div className='tw-text-red-700 tw-mt-1'>{error}</div>
      )}
    </div>
  )
}
