import type { Permission } from '../types'
import { mapById } from '../util'

export const teamPermissions: Permission[] = [
  {
    id: 'team.teammates.view',
    label: 'View Teammates',
    description: 'User can view teammates / handbook collaborators',
  },
  {
    id: 'team.teammates.edit',
    label: 'Edit Teammates',
    description: 'User can edit teammates / handbook collaborators',
  },
  {
    id: 'documents.archive',
    action: 'archive',
    label: 'Archive Documents',
    description: 'User can archive documents',
  },
  {
    id: 'documents.comment',
    action: 'edit',
    label: 'Comment on Documents',
    description: 'User can comment on documents',
  },
  {
    id: 'documents.create',
    label: 'Create Documents',
    description: 'User can create documents',
  },
  {
    id: 'documents.delete',
    action: 'delete',
    label: 'Delete Documents',
    description: 'User can delete documents',
  },
  {
    id: 'documents.edit',
    action: 'edit',
    label: 'Edit Documents',
    description: 'User can edit documents',
  },
  {
    id: 'documents.publish',
    action: 'publish',
    label: 'Publish Documents',
    description: 'User can publish documents',
  },
  {
    id: 'documents.view',
    action: 'view',
    label: 'View Documents',
    description: 'User can view documents',
  },
  {
    id: 'handbooks.create',
    label: 'Create Handbook',
    description: 'User can create handbooks',
  },
  {
    id: 'lawChanges.view',
    label: 'View Law Changes',
    description: 'User can view law changes',
  },
  {
    id: 'policyLibrary.view',
    label: 'View Policy Library',
    description: 'User can view the policy library',
  },
  {
    id: 'people.report',
    label: 'Report on People',
    description: 'User can report on people on the team',
  },
  {
    id: 'properties.manage',
    label: 'Manage Properties',
    description: 'User can manage properties on the team',
  },
  {
    id: 'properties.view',
    label: 'View Properties',
    description: 'User can view properties on the team',
  },
  {
    id: 'reviewers.edit',
    label: 'Edit Reviewers',
    description: 'User can edit reviews on a document',
  },
  {
    id: 'assets.manage',
    label: 'Manage Assets',
    description: 'User can manage assets on the team',
  },
]

export const teamPermissionsById = mapById(teamPermissions)
