import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { type AlertType, alertTypesMap } from './AlertType'

export function AlertIcon({
  className = '',
  type = 'warning',
}: {
  className?: string
  type: AlertType
}) {
  const { icon } = alertTypesMap.get(type)
  return (
    <div className={`alert-icon alert-icon-${type} ${className}`}>
      <FontAwesomeIcon fixedWidth icon={icon} />
    </div>
  )
}
