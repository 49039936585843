import type { IconProp } from '@fortawesome/fontawesome-svg-core'
import React from 'react'
import './alert.scss'

export type AlertType = 'upgrade' | 'info' | 'success' | 'warning' | 'danger'

export const alertTypes: AlertType[] = [
  'upgrade',
  'info',
  'success',
  'warning',
  'danger',
]

export type AlertTypeMetadata = {
  icon: IconProp
}

export type AlertVariant = 'small' | 'large' | 'stacked'

export const alertTypesMap = new Map<AlertType, AlertTypeMetadata>([
  [
    'upgrade',
    {
      icon: 'sparkles',
    },
  ],
  [
    'info',
    {
      icon: 'info',
    },
  ],
  [
    'success',
    {
      icon: 'check',
    },
  ],
  [
    'warning',
    {
      icon: 'exclamation',
    },
  ],
  [
    'danger',
    {
      icon: 'exclamation',
    },
  ],
])
