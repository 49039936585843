import { Link, Tooltip } from '@blissbook/ui/lib'
import { usePromise, useStateRef } from '@blissbook/ui/util/hooks'
import { cx } from '@emotion/css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import type { PDFDocumentProxy, PDFPageProxy } from 'pdfjs-dist/types/src/pdf'
import React from 'react'
import { useRef, useState } from 'react'
import { Waypoint } from 'react-waypoint'
// @ts-ignore: no types available
import PdfjsWorker from 'worker-loader!pdfjs-dist/legacy/build/pdf.worker.mjs'

// Configure pdfjs
const pdfjs = require('pdfjs-dist/legacy/build/pdf.mjs')
if (typeof window !== 'undefined' && 'Worker' in window) {
  pdfjs.GlobalWorkerOptions.workerPort = new PdfjsWorker()
}

// Resources
// https://mozilla.github.io/pdf.js/
// https://github.com/mozilla/pdf.js
// https://www.sitepoint.com/custom-pdf-rendering/

type PdfCacheEntry = {
  document: PDFDocumentProxy
  info: {
    Title?: string
  }
}

const pdfCache: Record<string, PdfCacheEntry> = {}

async function getPdf(url: string): Promise<PdfCacheEntry> {
  if (!url) return

  // Fetch, if not in cache
  if (!pdfCache[url]) {
    const document = await pdfjs.getDocument(url).promise
    const { info } = await document.getMetadata()
    pdfCache[url] = { document, info }
  }

  return pdfCache[url]
}

async function renderPdfPage(node: HTMLElement, pdfPage: PDFPageProxy) {
  // Determine the viewport
  const [x1, , x2] = pdfPage.view
  const pageWidth = x2 - x1
  const scale = node.offsetWidth / pageWidth
  const viewport = pdfPage.getViewport({ scale })

  // Create the canvas
  const canvas = document.createElement('canvas')
  canvas.width = viewport.width
  canvas.height = viewport.height
  canvas.style.display = 'block'
  canvas.style.marginBottom = '4px'

  // Render the PDF page
  const canvasContext = canvas.getContext('2d')
  pdfPage.render({ canvasContext, viewport })
  node.appendChild(canvas)
}

type UsePdfArgs = {
  disabled: boolean
  isInViewport: boolean
  url: string
}

/** Hook to fetch the PDF. Don't fetch the PDF until the PDF is in the viewport */
function usePdf({ disabled, isInViewport, url }: UsePdfArgs) {
  const [pdf, error, loading] = usePromise(() => {
    const showPdf = !disabled && isInViewport
    return getPdf(showPdf ? url : undefined)
  }, [disabled, isInViewport, url])
  return { error, loading, pdf }
}

type PdfViewerProps = React.HTMLAttributes<HTMLDivElement> & {
  disabled?: boolean
  thumbnailUrl?: string
  url: string
  width?: number | string
}

export function PdfViewer({
  className,
  disabled,
  style,
  thumbnailUrl,
  url,
  width,
  ...props
}: PdfViewerProps) {
  const [isInViewport, setIsInViewport] = useState(false)
  const [node, setNode] = useState<HTMLElement>()
  const viewportTimerIdRef = useRef<NodeJS.Timeout>()

  const { pdf, error, loading } = usePdf({
    disabled: !!thumbnailUrl,
    isInViewport,
    url,
  })

  const intitialState = {
    pageNumber: 0,
    pdf,
    width,
  }

  const [stateRef, setState] = useStateRef(intitialState)

  // Reset the state?
  const state = stateRef.current
  if (pdf !== state.pdf || width !== state.width) {
    setState(intitialState)
  }

  /** When enterinf the viewport, set a timer to render the PDF */
  function onEnterViewport() {
    viewportTimerIdRef.current = setTimeout(() => setIsInViewport(true), 200)
  }

  /** If we leave the viewport too quickly, kill the timer */
  function onLeaveViewport() {
    if (!viewportTimerIdRef.current) return

    clearTimeout(viewportTimerIdRef.current)
    viewportTimerIdRef.current = undefined
  }

  async function renderNextPage() {
    const pageNumber = state.pageNumber + 1
    const pdfPage = await pdf.document.getPage(pageNumber)
    await renderPdfPage(node, pdfPage)
    setState({ ...state, pageNumber })
  }

  const { pageNumber } = state
  const key = [url, width].join('-')
  return (
    <div
      {...props}
      key={key}
      className={cx(
        'pdf-viewer tw-relative tw-flex tw-justify-center tw-overflow-hidden tw-bg-gray-400 tw-border tw-border-gray-400 tw-group',
        thumbnailUrl ? 'tw-items-start' : 'tw-items-center',
        className,
      )}
      css={{
        maxWidth: '100%',
        '&:hover .pdf-viewer-header': {
          top: 0,
        },
      }}
      style={{ ...style, width }}
    >
      <Waypoint onEnter={onEnterViewport} onLeave={onLeaveViewport} />

      {thumbnailUrl ? (
        <>
          {' '}
          <img alt={thumbnailUrl} className='tw-w-full' src={thumbnailUrl} />
          <a
            className={cx(
              'tw-block tw-absolute tw-left-0 tw-top-0 tw-z-10 tw-flex tw-items-center tw-justify-center tw-w-full tw-h-full hover:tw-no-underline',
              disabled && 'tw-pointer-events-none',
            )}
            href={url}
            target='_blank'
            rel='noreferrer'
          >
            {!disabled && (
              <>
                <span
                  className='tw-absolute tw-left-0 tw-top-0 tw-w-full tw-h-full tw-opacity-0 tw-transition-opacity tw-duration-300 group-hover:tw-opacity-70'
                  style={{
                    backgroundColor: 'var(--brand-primary-contrast-color)',
                  }}
                />

                <span
                  className='tw-flex tw-items-center btn btn-primary tw-z-10'
                  style={{
                    backgroundColor: 'var(--brand-primary-color)',
                    borderColor: 'var(--brand-primary-color)',
                    color: 'var(--brand-primary-contrast-color)',
                  }}
                >
                  <FontAwesomeIcon
                    className='tw-mr-2'
                    icon={['fas', 'external-link']}
                  />
                  View PDF
                </span>
              </>
            )}
          </a>
        </>
      ) : !isInViewport || loading ? (
        <FontAwesomeIcon className='tw-ml-2' icon='spinner' spin />
      ) : error ? (
        <div className='tw-text-red-700'>Error</div>
      ) : (
        pdf && (
          <>
            {!disabled && (
              <PdfViewerHeader
                className='pdf-viewer-header tw-transition-all tw-absolute tw-left-0 tw-right-0'
                css={{
                  height: headerHeight,
                  top: -headerHeight,
                }}
                title={pdf.info.Title}
                url={url}
              />
            )}

            <div
              className={cx(
                'tw-h-full tw-w-full pdf-viewer-body',
                disabled ? 'tw-overflow-hidden' : 'tw-overflow-y-scroll',
              )}
            >
              <div ref={setNode} />
              {pageNumber < pdf.document.numPages && (
                <Waypoint key={pageNumber + 1} onEnter={renderNextPage} />
              )}
            </div>
          </>
        )
      )}
    </div>
  )
}

const headerHeight = 42

type PdfViewerHeaderProps = React.HTMLAttributes<HTMLDivElement> & {
  title?: string
  url: string
}

const PdfViewerHeader: React.FC<PdfViewerHeaderProps> = ({
  className,
  title,
  url,
  ...props
}) => {
  return (
    <div
      {...props}
      className={cx(
        'tw-flex tw-items-center tw-px-2 tw-bg-black/70 tw-text-white',
        className,
      )}
    >
      <div className='ellipsis' css={{ flex: 1, minWidth: 0 }}>
        {title}
      </div>

      <Tooltip content='Open PDF' placement='top'>
        <Link
          className='btn btn-dark btn-sm tw-ml-2'
          href={url}
          target='_blank'
        >
          <FontAwesomeIcon icon={['fas', 'external-link']} />
        </Link>
      </Tooltip>
    </div>
  )
}
