import classnames from 'classnames'
import { useField } from 'formik'
import React from 'react'
import { DateInput } from '../../input'

export const Field = ({
  className,
  hideError = false,
  id,
  label,
  name,
  style,
}) => {
  const [field, { error, touched }, { setValue }] = useField(name)
  const showError = touched && error
  id = id || name
  return (
    <div className={classnames('form-group', className)} style={style}>
      <If condition={label}>
        <label
          children={label}
          className={classnames('tw-block tw-flex-1 tw-mb-0', {
            'tw-text-red-700': showError,
          })}
          htmlFor={id}
        />
      </If>

      <div className={classnames('form-control', { 'is-invalid': showError })}>
        <DateInput
          className='tw-w-full'
          id={id}
          name={name}
          onChangeValue={setValue}
          value={field.value}
        />
      </div>

      <If condition={showError && field.value && !hideError}>
        <div className='tw-text-red-700 tw-mt-1'>{error}</div>
      </If>
    </div>
  )
}
