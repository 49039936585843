import {
  type Property,
  type PropertyValue,
  type PropertyValueInput,
  propertyTypes,
} from '@blissbook/lib/properties'
import React from 'react'
import {
  type ArchivePropertyFunction,
  PropertyEditor,
  type UpdatePropertyFunction,
} from './PropertyEditor'
import { PropertyRowLayout } from './PropertyRowLayout'
import {
  PropertyValueEditor,
  type PropertyValueEditorProps,
} from './PropertyValueEditor'
import { PropertyValueView } from './PropertyValueView'

export type PropertyValuesEditorProps = PropertyValueEditorProps & {
  archiveProperty?: ArchivePropertyFunction
  disabled?: boolean
  setPropertyValue: (
    propertyId: string,
    value: PropertyValueInput | null,
  ) => void
  property: Property
  value: PropertyValue | undefined
  updateProperty?: UpdatePropertyFunction | undefined
}

export function PropertyRow({
  archiveProperty,
  disabled,
  property,
  searchPeople,
  setPropertyValue,
  updateProperty,
  value,
}: PropertyValuesEditorProps) {
  const { icon } = propertyTypes[property.type]

  const propertyEditor = (
    <PropertyEditor
      archiveProperty={archiveProperty}
      property={property}
      updateProperty={updateProperty}
    />
  )

  const valueEditor = (
    <PropertyValueEditor
      property={property}
      onChange={async (value) => setPropertyValue(property.id, value)}
      searchPeople={searchPeople}
      updateProperty={updateProperty}
      value={value}
    />
  )

  return (
    <PropertyRowLayout
      disabled={disabled}
      icon={icon}
      label={property.label}
      propertyEditor={disabled ? undefined : propertyEditor}
      valueEditor={valueEditor}
      valueViewer={
        <PropertyValueView
          disabled={disabled}
          property={property}
          value={value}
        />
      }
    />
  )
}
