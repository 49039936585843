import { cx } from '@emotion/css'
import {
  autoUpdate,
  flip,
  safePolygon,
  useFloating,
  useFocus,
  useHover,
  useInteractions,
  useRole,
} from '@floating-ui/react-dom-interactions'
import type { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import {
  type CSSProperties,
  type ReactNode,
  forwardRef,
  useContext,
  useState,
} from 'react'
import * as Popper from './Popper'

export const Context = Popper.Context
export const Provider = Popper.Provider

// Dropdown.Caret

export const Caret: React.FC<{
  className?: string
  icon?: IconProp
  style?: CSSProperties
}> = ({ className, icon = ['fas', 'chevron-down'], ...props }) => (
  <FontAwesomeIcon
    {...props}
    className={cx('dropdown-caret', className)}
    icon={icon}
  />
)

// Dropdown.ToggleButton

export type ToggleButtonProps = Popper.ToggleButtonProps & {
  caret?: IconProp | false
}

export const ToggleButton = forwardRef<HTMLButtonElement, ToggleButtonProps>(
  ({ caret = ['fas', 'chevron-down'], children, className, ...props }, ref) => {
    const { isOpen } = useContext(Popper.Context)
    return (
      <Popper.ToggleButton
        {...props}
        className={cx('dropdown-toggle', { open: isOpen }, className)}
        ref={ref}
      >
        {children}

        {caret && (
          <Caret
            className={children ? 'tw-ml-1.5' : 'tw-ml-0 tw-transform-none'}
            icon={caret}
          />
        )}
      </Popper.ToggleButton>
    )
  },
)

// Dropdown.Menu

export const Menu: React.FC<Popper.MenuProps> = ({
  className,
  onClick,
  ...props
}) => {
  const { setOpen } = useContext(Popper.Context)
  return (
    <Popper.Menu
      {...props}
      className={cx('dropdown-menu dropdown-menu-context', className)}
      onClick={(event) => {
        if (onClick) onClick(event)

        if (!event.defaultPrevented) {
          // If the user clicks a dropdown-item, close the closest dropdown-menu-context.
          const [dropdownMenuNode] = $(event.currentTarget).closest(
            '.dropdown-menu-context',
          )
          const [itemNode] = $(event.target).closest('.dropdown-item')
          const [itemDropdownMenuNode] = $(itemNode).closest(
            '.dropdown-menu-context',
          )
          const isItem = !!itemNode && itemDropdownMenuNode === dropdownMenuNode
          if (isItem) setOpen(false)
        }
      }}
    />
  )
}

// Dropdown.Divider

type DividerProps = React.HTMLAttributes<HTMLDivElement>

export const Divider = forwardRef<HTMLDivElement, DividerProps>(
  ({ className, ...props }, ref) => (
    <div {...props} className={cx('dropdown-divider', className)} ref={ref} />
  ),
)

// Dropdown.Header

type HeaderProps = React.HTMLAttributes<HTMLDivElement>

export const Header = forwardRef<HTMLDivElement, HeaderProps>(
  ({ className, ...props }, ref) => (
    <div {...props} className={cx('dropdown-header', className)} ref={ref} />
  ),
)

// Dropdown.Item

type ItemProps = React.HTMLAttributes<HTMLDivElement> & {
  active?: boolean
  checked?: boolean
  description?: string
  Icon?: (props: { className: string }) => JSX.Element
}

export const Item = forwardRef<HTMLDivElement, ItemProps>(
  (
    { active, checked, children, className, description, Icon, ...props },
    ref,
  ) => {
    // Prepend an icon?
    if (Icon) {
      children = (
        <div>
          <Icon className='dropdown-item-icon' />

          {children}
        </div>
      )
    }

    // Add a description?
    if (description) {
      children = (
        <div>
          {children}

          <div className='tw-font-normal tw-text-xs tw-ml-6 tw-whitespace-normal tw-w-44 tw-line-clamp-2'>
            {description}
          </div>
        </div>
      )
    }

    return (
      <div
        {...props}
        className={cx(
          'dropdown-item',
          checked && 'tw-flex tw-items-center tw-justify-between',
          { active },
          className,
        )}
        ref={ref}
      >
        {children}

        {checked && (
          <FontAwesomeIcon
            className='dropdown-item-icon dropdown-item-icon-check'
            icon='check'
          />
        )}
      </div>
    )
  },
)

// Dropdown.Submenu

export type SubmenuProps = React.HTMLAttributes<HTMLDivElement> & {
  item: ReactNode
  maxHeight?: number
}

export function Submenu({
  children,
  className,
  item,
  maxHeight,
}: SubmenuProps) {
  const [open, setOpen] = useState(false)

  const state = useFloating({
    placement: 'right-start',
    open,
    onOpenChange: setOpen,
    whileElementsMounted: autoUpdate,
    middleware: [flip()],
  })

  const hover = useHover(state.context, {
    enabled: true,
    handleClose: safePolygon(),
  })

  const focus = useFocus(state.context, {
    enabled: true,
  })

  const role = useRole(state.context, {
    role: 'tooltip',
  })

  const interactions = useInteractions([hover, focus, role])

  return (
    <>
      <div
        className='dropdown-item tw-pr-6'
        {...interactions.getReferenceProps()}
        ref={state.reference}
      >
        {item}

        <FontAwesomeIcon
          className='tw-absolute tw-right-2 tw-top-1/2 -tw-translate-y-1/2 tw-text-xs'
          icon={['far', 'chevron-right']}
        />
      </div>

      <div
        className={cx(
          'popper-menu dropdown-menu tw-transition-opacity tw-duration-200',
          className,
        )}
        {...interactions.getFloatingProps()}
        ref={state.floating}
        style={{
          opacity: open ? 1 : 0,
          position: state.strategy,
          top: state.y ?? 0,
          left: state.x ?? 0,
          maxHeight,
          overflow: maxHeight ? 'auto' : undefined,
        }}
      >
        {children}
      </div>
    </>
  )
}
